@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#privacyTop{
  background-color: #fafafa;
  @include props(pb, 315);

  @include mq(md) {
    @include props(pb, 430, 750);
    background-color: #fcfcfc;
  } 

  .privacyPolicy{
    @include props(pt, 215);
    @include props(pb, 65);

    @include mq(md) {
      @include props(pt, 85, 750);
      @include props(pb, 85, 750);
    } 

    .privacyPolicyTit{
      font-family: $noto-serif;
      @include fz_vw(22);
      line-height: 1.27;
      text-align: center;
      color: #6e147f;
      @include props(mb, 65);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.67;
        letter-spacing: 1.35px;
        @include props(mb, 70, 750);
      } 
    }

    .privacyPolicyTxt{
      font-family: $noto-serif;
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      color: #5a5a5a;
      @include props(mb, 30);

      @include mq(md) {
        @include fz_vw(20);
        line-height: 2.4;
        letter-spacing: .75px;
        text-align: center;
        @include props(mb, 50, 750);
      } 
    }
    .privacyListTit{
      font-family: $noto-serif;
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      color: #5a5a5a;
      @include props(mt, 30);

      @include mq(md) {
        @include fz_vw(20);
        line-height: 2.4;
        letter-spacing: .75px;
        text-align: center;
        @include props(mt, 50, 750);
      } 
    }
    .privacyListCon{
      font-family: $noto-serif;
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      color: #5a5a5a;
      @include props(mb, 30);

      &:last-of-type{
        margin-bottom: 0;
      }

      @include mq(md) {
        @include fz_vw(20);
        line-height: 2.4;
        letter-spacing: .75px;
        text-align: center;
        @include props(mb, 50, 750);
      } 
    }
  }

  .actOnSCT{
    @include props(pt, 65);
    @include props(pb, 112);

    @include mq(md) {
      @include props(pt, 85, 750);
      @include props(pb, 60, 750);
    } 

    .actOnSCTTit{
      font-family: $noto-serif;
      @include fz_vw(22);
      line-height: 1.27;
      color: #6e147f;
      text-align: center;
      @include props(mb, 60);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.67;
        letter-spacing: 1.35px;
        @include props(mb, 50, 750);
      } 
    }
    .actOnSCTList{
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      color: #5a5a5a;
      @include props(mb, 30);

      @include mq(md) {
        font-family: $noto-serif;
        @include fz_vw(20);
        font-weight: normal;
        line-height: 2.4;
        letter-spacing: .75px;
        @include props(mb, 50, 750);
      } 
    }

    p{
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      color: #5a5a5a;

      @include mq(md) {
        font-family: $noto-serif;
        @include fz_vw(20);
        font-weight: normal;
        line-height: 2.4;
        letter-spacing: .75px;
      } 
    }
  }

  .contact{    
    @include props(pt, 112);

    @include mq(md) {
      @include props(pt, 60, 750);
    } 

    .contactTit{
      font-family: $noto-serif;
      @include fz_vw(22);
      line-height: 1.27;
      color: #6e147f;
      text-align: center;
      @include props(mb, 60);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.67;
        letter-spacing: 1.35px;
        @include props(mb, 45, 750);
      } 
    }

    .contactTxt{
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      text-align: center;
      color: #5a5a5a;

      a{
        color: inherit;
      }

      @include mq(md) {
        font-family: $noto-serif;
        @include fz_vw(20);
        font-weight: normal;
        line-height: 2.4;
        letter-spacing: .75px;
      } 
    }
  }
}