@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;

  &.privacy,
  &.notFound{
    background-color: #fafafa;

    @include mq(md) {
      background-color: #fcfcfc;
    } 
  }

  &.fixedBody {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;

  @include mq(md) {
    @include props(pt, 145, 750);
  }

  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.maxContainer {
  max-width: 1600px;
  width: 100%;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.container {
  max-width: 1406px;
  width: 100%;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.minContainer {
  max-width: 1306px;
  width: 100%;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.leastContainer{
  max-width: 1006px;
  width: 100%;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 80, 750);
    @include props(pl, 80, 750);
  }
}


a {
  color: #555;
}
/* 共通デザイン
------------------------------------------------------------- */
.btnTxt{
  font-family: $noto-serif;
  @include fz_vw(22);
  line-height: 1.27;
  text-align: center;
  color: #6e147f;
  @include props(mb, 25);

  &.big{
    @include fz_vw(39);
    @include props(mb, 56);
  }

  @include mq(md) {
    @include fz_vw(32);
    @include props(mb, 40, 750);

    &.big{
      @include fz_vw(32);
      @include props(mb, 40, 750);
    }
  }
}

.purpleBtn{
  max-width: 31.596%;
  width: 100%;
  margin: 0 auto;

  &.big{
    max-width: 29.576%;
  }

  a{
    display: block;
    @include fz_vw(28);
    font-weight: bold;
    line-height: 1;
    letter-spacing: 2.1px;
    text-align: center;
    color: #fff;
    background-color: #6e147f;
    transition: opacity .3s;
    @include props(pt, 20);
    @include props(pb, 22);
  }

  @include mq(md) {
    max-width: 100%;
    @include props(w, 510, 750);

    &.big{
      max-width: 100%;
      @include props(w, 510, 750);
    }

    a{
      @include fz_vw(36);
      letter-spacing: 1.35px;
      @include props(pt, 20, 750);
      @include props(pb, 22, 750);
    }
  }
}

.pageTopBox{
  @include props(pb, 35);

  @include mq(md) {
    @include props(pr, 94, 750);
    @include props(pb, 24, 750);
  }

  .pageTop{
    position: relative;
    margin: 0 0 0 auto;
    cursor: pointer;
    @include props(w, 54);
    @include props(r, -26);

    @include mq(desk) {
      @include props(r, 0);
    } 
    @include mq(tab) {
      @include props(r, 0);
    } 
    
    @include mq(md) {
      @include props(w, 52, 750);
      @include props(r, 0);
    }
  }
}

/* 404
------------------------------------------------------------- */
#notFoundTop{
  background-color: #fafafa;
  @include props(pb, 150);

  @include mq(md) {
    @include props(pb, 120, 750);
    background-color: #fcfcfc;
  } 

  .notFoundCon{
    text-align: center;
    @include props(pt, 215);
    @include props(pb, 65);

    @include mq(md) {
      @include props(pt, 85, 750);
      @include props(pb, 85, 750);
    } 

    .notFoundTit{
      font-family: $noto-serif;
      @include fz_vw(22);
      line-height: 1.27;
      text-align: center;
      color: #6e147f;
      @include props(mb, 65);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.67;
        letter-spacing: 1.35px;
        @include props(mb, 70, 750);
      } 
    }

    .notFoundTxt{
      display: inline-block;
      font-family: $noto-serif;
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2.5;
      color: #5a5a5a;

      a{
        color: inherit;
        text-decoration: underline;
      }

      @include mq(md) {
        @include fz_vw(20);
        line-height: 2.4;
        letter-spacing: .75px;
        text-align: left;
      } 
    }
  }
}
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  @include props(pt, 40);
  @include props(pb, 20);
  clear: both;

  @include mq(md) {
    @include props(pt, 30, 750);
    @include props(pb, 50, 750);
  }
  ol {
    @include clearfix;
    position: relative;
    width: 100%;
    word-break: break-all;
    text-align: left;
    line-height: 1;
    color: #acabab;
    margin: 0 auto;
    @include mq(md) {
      max-width: initial;
    }
    &>li {
      display: inline;
      @include fz_vw(18);
      font-weight: 500;
      line-height: 1.22;
      letter-spacing: 0.9px;
      color: #666;
  
      @include mq(md) {
        @include fz_vw(20);
        letter-spacing: 0.5px;
      }

      a {
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: 0.9px;
        color: #666;

        @include mq(md) {
          @include fz_vw(20);
          letter-spacing: 0.5px;
        }
      }
      &:first-child {
        a {
          text-decoration: none;
        }
      }
      &+li::before {
        @include fz_vw(16);
        @include props(pr, 10);
        @include props(pl, 8);
        color: #444444;
        content: "＞";

        @include mq(md) {
          @include fz_vw(20);
          @include props(pr, 10, 750);
          @include props(pl, 10, 750);
        }
      }
    }
  }
}