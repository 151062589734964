@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#zapparelTop{
  @include props(pb, 145);

  @include mq(md) {
    @include props(pb, 145, 750);
  } 

  .mainview{
    img{
      max-width: 100%;
      width: 100%;
    }

    .txtBox{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .txt01{
      max-width: 44.095%;
      width: 100%;
      position: absolute;
      @include props(t, 330);
      left: 0;
      right: 0;
      margin: 0 auto;

      @include mq(desk) {
        @include props(t, 240);
      } 
      @include mq(tab) {
        @include props(t, 240, 768);
      } 

      @include mq(md) {
        max-width: 100%;
        @include props(t, 356, 750);
        @include props(w, 590, 750);
      } 
    }
    .txt02{
      max-width: 35.2234%;
      width: 100%;
      position: absolute;
      @include props(t, 530);
      left: 0;
      right: 0;
      margin: 0 auto;

      @include mq(desk) {
        @include props(t, 380);
      } 
      @include mq(tab) {
        @include props(t, 380, 768);
      } 

      @include mq(md) {
        max-width: 100%;
        @include props(t, 498, 750);
        @include props(w, 442, 750);
      } 
    }
  }

  .zapparelAbout{
    @include props(pt, 170);
    @include props(pb, 120);

    @include mq(md) {
      @include props(pt, 74, 750);
      @include props(pb, 110, 750);
    } 

    .zapparelAboutTit{
      @include fz_vw(70);
      line-height: 1;
      letter-spacing: 5.25px;
      text-align: center;
      color: #6e147f;
      @include props(mb, 90);

      @include mq(md) {
        font-weight: bold;
        letter-spacing: .7px;
        @include props(mb, 70, 750);
      } 
    }

    .zapparelAboutTxt{
      font-family: $noto-serif;
      @include fz_vw(16);
      line-height: 2.45;
      letter-spacing: 1.2px;
      text-align: center;
      color: #3b444b;

      @include mq(md) {
        @include fz_vw(20);
        line-height: 2.4;
        letter-spacing: .75px;
        text-align: left;
      } 
    }
  }

  .zapparelFirstProduction{
    @include props(pb, 90);

    @include mq(md) {
      @include props(pb, 90, 750);
    } 

    .zapparelFirstProductionTit{
      font-family: $noto-serif;
      @include fz_vw(32);
      font-weight: bold;
      line-height: 0.88;
      letter-spacing: 1.6px;
      text-align: center;
      color: #3b444b;
      @include props(mb, 90);

      @include mq(md) {
        @include fz_vw(20);
        line-height: 1.4;
        letter-spacing: .5px;
        @include props(mb, 90, 750);
      } 
    }

    .flex{
      max-width: 62.402%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      @include props(mt, 90);

      @include mq(md) {
        max-width: 100%;
        @include props(mt, 90, 750);
      } 

      .img{
        max-width: 47.85%;
        width: 100%;
        @include props(mt, 54);

        @include mq(md) {
          @include props(mt, 45, 750);
        } 

        &.first{
          max-width: 100%;
          @include props(mt, 0);
        }
      }
    }
  }

  .zapparelSecondProduction{
    @include props(pt, 90);
    @include props(pb, 110);

    @include mq(md) {
      @include props(pt, 90, 750);
      @include props(pb, 110, 750);
    } 

    .zapparelSecondProductionTit{
      font-family: $noto-serif;
      @include fz_vw(32);
      font-weight: bold;
      line-height: 0.88;
      letter-spacing: 1.6px;
      text-align: center;
      color: #3b444b;
      @include props(mb, 50);

      @include mq(md) {
        @include fz_vw(20);
        line-height: 1.4;
        letter-spacing: .5px;
        @include props(mb, 10, 750);
      } 
    }
    .zapparelSecondProductionSubTit{
      font-family: $noto-serif;
      @include fz_vw(22);
      font-weight: bold;
      line-height: 1.27;
      letter-spacing: 1.1px;
      text-align: center;
      color: #3b444b;
      @include props(mb, 80);

      @include mq(md) {
        @include fz_vw(20);
        line-height: 1.4;
        letter-spacing: .5px;
        @include props(mb, 80, 750);
      } 
    }

    .mainImg{
      max-width: 62.402%;
      margin: 0 auto;

      @include mq(md) {
        max-width: 100%;
      } 
    }
    .flex{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;

      .img{
        max-width: 23.697%;
        width: 100%;
        @include props(mr, 20);
        @include props(mt, 54);

        @include mq(md) {
          @include props(mr, 10, 750);
          @include props(mt, 54, 750);
        } 

        &:nth-of-type(4n){
          margin-right: 0;
        }
      }
    }
  }

  .zapparelDesc{
    @include props(pt, 110);

    .zapparelDescTit{
      font-family: $noto-serif;
      @include fz_vw(39);
      font-weight: bold;
      line-height: 1;
      letter-spacing: 1.95px;
      text-align: center;
      color: #3b444b;
      @include props(mb, 40);

      @include mq(md) {
        @include fz_vw(26);
        line-height: 1.4;
        letter-spacing: .5px;
        @include props(mb, 10, 750);
      } 
    }
    .zapparelDescSubTit{
      font-family: $noto-serif;
      @include fz_vw(24);
      font-weight: bold;
      line-height: 1.17;
      letter-spacing: 1.2px;
      text-align: center;
      color: #3b444b;
      @include props(mb, 114);

      @include mq(md) {
        @include fz_vw(22);
        line-height: 1.4;
        letter-spacing: .5px;
        @include props(mb, 114, 750);
      } 
    }
    .img{
      @include props(mb, 290);

      @include mq(md) {
        @include props(mb, 150, 750);
      } 
    }
  }
}