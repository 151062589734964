@charset "utf-8";
/* =====================================================
フッター
===================================================== */
#footer{
  .footerContents{
    background-color: #efe5f1;
    @include props(pt, 90);
    @include props(pb, 140);

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pb, 110, 750);
      @include props(pr, 80, 750);
      @include props(pl, 80, 750);
    }

    .container{
      display: flex;

      ul{
        @include props(mr, 110);
      }

      @include mq(md) {
        display: block;
        
        ul{
          @include props(mr, 0);
        }
      }
    }

    .nav{
      @include fz_vw(20);
      line-height: 1.2;
      letter-spacing: 1.5px;
      color: #3b444b;
      @include props(mb, 30);

      &:last-of-type{
        margin-bottom: 0;
      }

      @include mq(md) {
        letter-spacing: .75px;
        @include props(mb, 42, 750);

        &:last-of-type{
          @include props(mb, 42, 750);
        }
      }
    }
  }
  .copyRight{
    display: block;
    @include fz_vw(16);
    line-height: 1;
    text-align: center;
    color: #3b444b;
    background-color: #f1f1f1;
    @include props(pt, 40);
    @include props(pb, 40);

    @include mq(md) {
      line-height: 1.2;
      @include props(pt, 40, 750);
      @include props(pb, 40, 750);
    }
  }
}