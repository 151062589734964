@charset "utf-8";
/* アニメーション関連---------------------- */
.fadeIn {
  $fadeIn: &;
  opacity: 0;
  visibility:hidden;
  transform: translateY(50px);
  transition: opacity 1s .2s, transform 1s .2s;
  backface-visibility: hidden;

  img{
    backface-visibility: hidden;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
    &.fadeIn2{
      transform: none;
    }
    #{$fadeIn}_second {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
    #{$fadeIn}_third {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
  &.fadeIn2{
    transform: translateY(30px);
  }
  #{$fadeIn}_second {
    opacity: 0;
    visibility:hidden;
    transform: translateY(30px);
    transition: opacity 1s .6s, transform 1s .6s;
  }
  #{$fadeIn}_third {
    opacity: 0;
    visibility:hidden;
    transform: translateY(30px);
    transition: opacity 1s 1s, transform 1s 1s;
  }
}