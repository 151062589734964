@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

// ローディング画面
#loading {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	background: #ffffff;
	opacity: 1;
	z-index: 100000;
}