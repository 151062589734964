@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  @include props(h, 100);
    
  @include mq(md) {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, .9);
    transition: .5s;
    @include props(h, 145, 750);

    &.changeHeight{
      @include props(h, 100, 750);

      .headerLogo{
        @include mq(md) {
          @include props(w, 205, 750);
        }
      }
    }
  }

  .container{
    display: flex;
    align-items: center;
    height: 100%;
  }

  .headerLogo{
    @include props(w, 206);

    @include mq(md) {
      transition: .5s;
      @include props(w, 265, 750);
    }
  }
  
  .navBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    @include props(pr, 40, 750);
    @include props(w, 90, 750);
    @include props(h, 32, 750);
    cursor: pointer;

    span {
      display: inline-block;
      position: absolute;
      @include props(r, 40, 750);
      @include props(w, 50, 750);
      height: 1px;
      background-color: #4d4d4d;
      transition: transform .5s ease-in-out , opacity .5s .9s ease-in-out , background-color .5s .9s ease-in-out;
      &:nth-of-type(1) {
        @include props(t, 28, 750);
      }
      &:nth-of-type(2) {
        top: 49%;
      }
      &:nth-of-type(3) {
        @include props(b, 28, 750);
      }
    }
    &.isOpen {
      span {
        transition: transform .5s ease-in-out , opacity .5s ease-in-out;
        &:nth-of-type(1),
        &:nth-of-type(3) {
          top: 50%;
          background-color: #fff;
        }
        &:nth-of-type(1) {
          transform: rotate(-135deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: rotate(135deg);
        }
      }
    }
  }

  .globalNav{
    @include mq(md) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 998;
      visibility: hidden;
      -webkit-overflow-scrolling: touch;
      transition: visibility .7s .8s;

      &.isShow {
        visibility: visible;
        transition: visibility .7s;
        &::after {
          transition: transform .7s;
          transform: translateX(0);
        }
        ul {
          transition: opacity .8s .8s, transform .7s .8s;
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(110, 20, 127, .9);
        z-index: -1;
        transform: translateX(100%);
        transition: transform .7s .9s;
      }
      
      ul{
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity .8s, transform .7s;
      }
    }

    ul{
      display: flex;
      align-items: center;

      .nav{
        @include fz_vw(16);
        line-height: 1.2;
        letter-spacing: 1.2px;
        color: #3b444b;
        @include props(ml, 75);

        a{
          color: inherit;
        }
      }

      @include mq(md) {
        display: block;
        @include props(pl, 125, 750);
        @include props(pr, 40, 750);
        @include props(pt, 250, 750);

        .nav{
          @include fz_vw(32);
          font-weight: bold;
          color: #fff;
          @include props(ml, 0);
          @include props(mb, 60, 750);
        }
      }
    }
  }
}