@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#indexTop{
  @include props(pb, 250);

  @include mq(md) {
    @include props(pb, 250, 750);
  } 

  .mainview{
    position: relative;
    img{
      max-width: 100%;
      width: 100%;
    }

    .txtBox{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .txt01{
      max-width: 50.578%;
      width: 100%;
      position: absolute;
      @include props(t, 412);
      left: 0;
      right: 0;
      margin: 0 auto;

      @include mq(desk) {
        @include props(t, 292);
      } 
      @include mq(tab) {
        @include props(t, 292, 768);
      } 

      @include mq(md) {
        max-width: 100%;
        @include props(t, 417, 750);
        @include props(w, 623, 750);
      } 
    }
    .txt02{
      max-width: 29.832%;
      width: 100%;
      position: absolute;
      @include props(t, 587);
      left: 0;
      right: 0;
      margin: 0 auto;

      @include mq(desk) {
        @include props(t, 417);
      } 
      @include mq(tab) {
        @include props(t, 417, 768);
      } 

      @include mq(md) {
        max-width: 100%;
        @include props(t, 520, 750);
        @include props(w, 488, 750);
      } 
    }
    .txt03{
      @include mq(md) {
        max-width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        @include props(t, 645, 750);
        @include props(w, 568, 750);
      } 
    }
  }

  .aboutBathPowder{
    @include props(pt, 85);
    @include props(pb, 117);

    @include mq(md) {
      @include props(pt, 110, 750);
      @include props(pb, 90, 750);
    }

    .aboutBathPowderTit{
      span{
        display: block;
        font-family: $noto-serif;
        @include fz_vw(32);
        font-weight: normal;
        line-height: 1.31;
        text-align: center;
        color: #3b444b;
        @include props(mb, 50);
      }
      
      @include fz_vw(70);
      font-weight: bold;
      line-height: 1;
      letter-spacing: 1.4px;
      text-align: center;
      color: #6e147f;
      @include props(mb, 42);

      @include mq(md) {
        span{
          @include fz_vw(36);
          line-height: 1.17;
          @include props(mb, 80, 750);
        }

        letter-spacing: .7px;
        @include props(mb, 54, 750);
      }
    }

    .aboutBathPowderTxt{
      font-family: $noto-serif;
      @include fz_vw(16);
      line-height: 2;
      letter-spacing: 1.2px;
      text-align: center;
      color: #3b444b;
      @include props(mb, 70);

      @include mq(md) {
        @include fz_vw(20);
        line-height: 2.4;
        letter-spacing: .75px;
        @include props(mb, 94, 750);
      }
    }

    .img{
      max-width: 47.947%;
      width: 100%;
      margin: 0 auto;
      @include props(mb, 94);

      @include mq(md) {
        max-width: 100%;
        @include props(w, 590, 750);
        @include props(mb, 90, 750);
      }
    }

    .youtubeBox{
      max-width: 952px;
      width: 100%;
      margin: 0 auto;

      @include mq(md) {
        padding: 0;
      }
    }
    .youtube{
      position: relative;
      max-width: 100%;
      padding-bottom: 56.25%;
      background-color: #f1f1f1;
      @include props(mt, 290);

      @include mq(md) {
        @include props(mt, 150, 750);
      }
      
      iframe{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .bathPowderDesc{
    @include props(pt, 117);
    @include props(pb, 172);

    @include mq(md) {
      @include props(pt, 90, 750);
      @include props(pb, 100, 750);
    }

    .bathPowderDescTit{
      font-family: $noto-serif;
      @include fz_vw(36);
      line-height: 1.78;
      letter-spacing: normal;
      text-align: center;
      color: #3b444b;
      @include props(mb, 70);

      @include mq(md) {
        @include props(mb, 60, 750);
      }
    }
    .bathPowderDescTxt{
      max-width: 76.304%;
      width: 100%;
      font-family: $noto-serif;
      @include fz_vw(16);
      line-height: 2;
      letter-spacing: 1.2px;
      text-align: center;
      color: #3b444b;
      margin: 0 auto;
      @include props(mb, 90);

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(20);
        line-height: 2.4;
        letter-spacing: .75px;
        @include props(mb, 90, 750);
      }
    }
    .bathPowderDescPoint{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @include props(mb, 100);
      
      @include mq(md) {
        @include props(mb, 280, 750);
      }

      .point{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-family: $noto-serif;
        @include fz_vw(32);
        font-weight: 500;
        line-height: 1.13;
        letter-spacing: 2.4px;
        text-align: center;
        color: #6e147f;
        border: solid 2px #6e147f;
        border-radius: 100%;
        @include props(w, 240);
        @include props(h, 240);
        @include props(mr, 40);

        &:nth-of-type(4n){
          margin-right: 0;
        }

        @include mq(md) {
          letter-spacing: 1.2px;
          border: solid 1px #6e147f;
          @include props(w, 196, 750);
          @include props(h, 196, 750);
          @include props(mr, 30, 750);
          @include props(mb, 10, 750);

          &:nth-of-type(4n){
            @include props(mr, 30, 750);
          }
          &:nth-of-type(5n){
            margin-right: 0;
          }
        }
      }
    }

    .mainEfficacy{
      @include props(mb, 84);
      
      @include mq(md) {
        @include props(mb, 130, 750);
      }

      .mainEfficacyTit{
        font-family: $noto-serif;
        @include fz_vw(36);
        line-height: 1.78;
        text-align: center;
        color: #3b444b;
        @include props(mb, 100);
      
        @include mq(md) {
          @include props(mb, 60, 750);
        }
      }
      .flex{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      
        @include mq(md) {
          display: block;
        }
      }
      .mainEfficacyBox{
        max-width: 50%;
        width: 100%;
        @include props(pr, 50);
        @include props(mb, 25);

        &:nth-of-type(2n){
          @include props(pr, 0);
          @include props(pl, 50);
        }
      
        @include mq(md) {
          max-width: 100%;
          @include props(pr, 0);
          @include props(mb, 105, 750);
  
          &:nth-of-type(2n){
            @include props(pr, 0);
            @include props(pl, 0);
          }
        }

        .mainEfficacyBoxTit{
          max-width: 46.656%;
          width: 100%;
          font-family: $noto-serif;
          @include fz_vw(24);
          line-height: 1;
          letter-spacing: 0.6px;
          text-align: center;
          color: #fff;
          background-color: #6e147f;
          border-radius: 10px;
          @include props(pt, 14);
          @include props(pb, 14);
          @include props(mb, 30);
      
          @include mq(md) {
            max-width: none;
            min-width: 40.596%;
            width: auto;
            display: inline-block;
            @include fz_vw(28);
            letter-spacing: 0.35px;
            border-radius: 5px;
            @include props(pt, 14, 750);
            @include props(pb, 14, 750);
            @include props(pr, 40, 750);
            @include props(pl, 40, 750);
            @include props(mb, 30, 750);
          }
        }
        .mainEfficacyBoxTxt{
          @include fz_vw(16);
          font-weight: 300;
          line-height: 2;
          color: #5a5a5a;
          @include props(mb, 45);
      
          @include mq(md) {
            @include fz_vw(20);
            @include props(mb, 65, 750);
          }
        }
        .supplement{
          display: flex;
          align-items: center;
          @include props(mb, 25);
      
          @include mq(md) {
            @include fz_vw(20);
            @include props(mb, 0, 750);
          }

          .txt{
            @include fz_vw(16);
            font-weight: 300;
            line-height: 1;
            text-align: center;
            color: #5a5a5a;
      
            @include mq(md) {
              @include fz_vw(24);
            }  

            .purple{
              display: inline-block;
              @include fz_vw(16);
              font-weight: 300;
              line-height: 1;
              text-align: center;
              color: #6e147f;
              border-radius: 5px;
              border: solid 1px #6e147f;
              @include props(pt, 8);
              @include props(pb, 8);
              @include props(pr, 20);
              @include props(pl, 20);
              @include props(mb, 6);
      
              @include mq(md) {
                @include fz_vw(23);
                border-radius: 3px;
                @include props(pt, 14, 750);
                @include props(pb, 14, 750);
                @include props(pr, 20, 750);
                @include props(pl, 20, 750);
                @include props(mb, 9, 750);
              }  
            }
            &.txt02{
              &::before{
                content: "＋";
                display: inline-block;
                @include fz_vw(16);
                font-weight: 300;
                line-height: 1;
                text-align: center;
                color: #6e147f;
                vertical-align: top;
                @include props(mt, 8);
                @include props(mr, 5);
                @include props(ml, 8);
      
                @include mq(md) {
                  @include fz_vw(24);
                  @include props(mt, 16, 750);
                  @include props(mr, 5, 750);
                  @include props(ml, 8, 750);
                }  
              }

              .purple{
                @include props(pr, 10);
                @include props(pl, 10);
      
                @include mq(md) {
                  @include props(pr, 15, 750);
                  @include props(pl, 15, 750);
                }  
              }
              .gray{
                display: block;
                text-align: right;
                @include props(pr, 10);
      
                @include mq(md) {
                  @include props(pr, 20, 750);
                }  
              }
            }
            &.txt03{
              &::before{
                content: "＝";
                display: inline-block;
                @include fz_vw(16);
                font-weight: 300;
                line-height: 1;
                text-align: center;
                color: #6e147f;
                vertical-align: top;
                @include props(mt, 10);
                @include props(mr, 25);
                @include props(ml, 8);
      
                @include mq(md) {
                  @include fz_vw(24);
                  @include props(mt, 14, 750);
                  @include props(mr, 20, 750);
                  @include props(ml, 12, 750);
                }  
              }

              .purple{
                @include fz_vw(35);
                border: none;
                @include props(pt, 0);
                @include props(pb, 2);
                @include props(pr, 0);
                @include props(pl, 0);
      
                @include mq(md) {
                  @include fz_vw(52);
                  @include props(pb, 4, 750);
                }  
              }
              .gray{
                display: block;
                text-align: right;
                @include props(pr, 24);
      
                @include mq(md) {
                  @include props(pr, 32, 750);
                }  
              }
            }
          }
        }
      }
    }
  }

  .howToUse{
    @include props(pt, 172);
    @include props(pb, 60);

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pb, 100, 750);
    }

    .howToUseTit{
      font-family: $noto-serif;
      @include fz_vw(32);
      line-height: 1.31;
      text-align: center;
      color: #3b444b;
      @include props(mb, 60);

      @include mq(md) {
        line-height: 1.78;
        @include props(mb, 60, 750);
      }
    }
    .howToUseTxt{
      font-family: $noto-serif;
      @include fz_vw(21);
      line-height: 2;
      letter-spacing: 1.58px;
      text-align: center;
      color: #3b444b;
      @include props(mb, 45);

      @include mq(md) {
        @include fz_vw(24);
        letter-spacing: .9px;
        @include props(mb, 140, 750);
      }
    }

    .img{
      @include mq(md) {
        padding: 0;
      }
    }

    .recommended{
      @include props(pt, 60);

      @include mq(md) {
        @include props(pt, 95, 750);
      }

      .recommendedTit{
        font-family: $noto-serif;
        @include fz_vw(32);
        line-height: 1.31;
        text-align: center;
        color: #3b444b;
        @include props(mb, 90);

        @include mq(md) {
          @include fz_vw(36);
          line-height: 1.78;
          @include props(mb, 90, 750);
        }
      }

      .recommendedList{
        .recommendedListTit{
          @include fz_vw(18);
          font-weight: 300;
          line-height: 2;
          color: #5a5a5a;

          @include mq(md) {
            @include fz_vw(20);
          }
        }
        .recommendedListCon{
          @include fz_vw(16);
          font-weight: 300;
          line-height: 2;
          color: #5a5a5a;
          @include props(mb, 80);

          &:last-of-type{
            margin-bottom: 0;
          }

          @include mq(md) {
            @include fz_vw(20);
            @include props(mb, 110, 750);
          }
        }
      }
    }
  }

  .faq{
    @include props(pt, 60);
    @include props(pb, 77);

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pb, 100, 750);
    }

    .faqTit{
      font-family: $noto-serif;
      @include fz_vw(32);
      line-height: 1.31;
      text-align: center;
      color: #3b444b;
      @include props(mb, 120);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.78;
        @include props(mb, 90, 750);
      }
    }
    .faqList{
      @include props(mb, 100);

      @include mq(md) {
        @include props(mb, 100, 750);
      }
    }
    .faqListTit{
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      color: #5a5a5a;
      border-bottom: solid 1px #707070;    
      @include props(pb, 4);
      @include props(mb, 25);

      @include mq(md) {
        @include fz_vw(20);
        @include props(pb, 4, 750);
        @include props(mb, 25, 750);
      }
    }
    .faqListCon{
      @include fz_vw(16);
      font-weight: 300;
      line-height: 2;
      color: #5a5a5a;
      @include props(mb, 60);

      &:last-of-type{
        margin-bottom: 0;
      }

      @include mq(md) {
        @include fz_vw(20);
        @include props(mb, 100, 750);
      }
    }
  }

  .attentionOnUsing{
    @include props(pt, 77);

    @include mq(md) {
      @include props(pt, 100, 750);
    }

    .attentionOnUsingTit{
      font-family: $noto-serif;
      @include fz_vw(32);
      line-height: 1.31;
      text-align: center;
      color: #3b444b;
      @include props(mb, 70);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.78;
        @include props(mb, 70, 750);
      }
    }

    .attentionList{
      max-width: 82.781%;
      margin: 0 auto;

      li{
        display: flex;
        align-items: baseline;
        @include fz_vw(16);
        font-weight: 300;
        line-height: 2;
        color: #5a5a5a;

        &::before{
          content: "●";
        }
      }

      @include mq(md) {
        max-width: 100%;
  
        li{
          @include fz_vw(20);
        }
      }
    }
  }
}