@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*tab設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}

body.privacy, body.notFound {
  background-color: #fafafa;
}

@media screen and (max-width: 767px) {
  body.privacy, body.notFound {
    background-color: #fcfcfc;
  }
}

body.fixedBody {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

@media screen and (max-width: 767px) {
  main {
    padding-top: 19.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  main {
    padding-top: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  main {
    padding-top: 10.61493vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  main {
    padding-top: 10.61493vw;
  }
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.maxContainer {
  max-width: 1600px;
  width: 100%;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .maxContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .maxContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .maxContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

.container {
  max-width: 1406px;
  width: 100%;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .container {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .container {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .container {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-left: 2.92826vw;
  }
}

.minContainer {
  max-width: 1306px;
  width: 100%;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .minContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .minContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .minContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

.leastContainer {
  max-width: 1006px;
  width: 100%;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .leastContainer {
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .leastContainer {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .leastContainer {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .leastContainer {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .leastContainer {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .leastContainer {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .leastContainer {
    padding-left: 5.85652vw;
  }
}

a {
  color: #555;
}

/* 共通デザイン
------------------------------------------------------------- */
.btnTxt {
  font-family: "Noto Serif JP", serif;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  line-height: 1.27;
  text-align: center;
  color: #6e147f;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1600px) {
  .btnTxt {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  .btnTxt {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1600px) {
  .btnTxt {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt {
    margin-bottom: 1.83016vw;
  }
}

.btnTxt.big {
  font-size: 3.9rem;
  font-size: 2.03125vw;
  margin-bottom: 2.91667vw;
}

@media screen and (min-width: 1600px) {
  .btnTxt.big {
    font-size: 3.9rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt.big {
    font-size: 2.85505vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt.big {
    font-size: 2.85505vw;
  }
}

@media screen and (max-width: 767px) {
  .btnTxt.big {
    font-size: 5.2vw;
  }
}

@media screen and (min-width: 1600px) {
  .btnTxt.big {
    margin-bottom: 56px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt.big {
    margin-bottom: 4.09956vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt.big {
    margin-bottom: 4.09956vw;
  }
}

@media screen and (max-width: 767px) {
  .btnTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btnTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btnTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btnTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  .btnTxt.big {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btnTxt.big {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt.big {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt.big {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btnTxt.big {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btnTxt.big {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btnTxt.big {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btnTxt.big {
    margin-bottom: 2.92826vw;
  }
}

.purpleBtn {
  max-width: 31.596%;
  width: 100%;
  margin: 0 auto;
}

.purpleBtn.big {
  max-width: 29.576%;
}

.purpleBtn a {
  display: block;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 2.1px;
  text-align: center;
  color: #fff;
  background-color: #6e147f;
  transition: opacity .3s;
  padding-top: 1.04167vw;
  padding-bottom: 1.14583vw;
}

@media screen and (min-width: 1600px) {
  .purpleBtn a {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn a {
    font-size: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  .purpleBtn a {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  .purpleBtn a {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn a {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn a {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  .purpleBtn a {
    padding-bottom: 22px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn a {
    padding-bottom: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn a {
    padding-bottom: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  .purpleBtn {
    max-width: 100%;
    width: 68vw;
    max-width: 510px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .purpleBtn {
    width: 510px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn {
    width: 37.33529vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn {
    width: 37.33529vw;
  }
}

@media screen and (max-width: 767px) {
  .purpleBtn.big {
    max-width: 100%;
    width: 68vw;
    max-width: 510px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .purpleBtn.big {
    width: 510px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn.big {
    width: 37.33529vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn.big {
    width: 37.33529vw;
  }
}

@media screen and (max-width: 767px) {
  .purpleBtn a {
    font-size: 3.6rem;
    font-size: 1.875vw;
    letter-spacing: 1.35px;
    padding-top: 2.66667vw;
    padding-bottom: 2.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .purpleBtn a {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn a {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn a {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .purpleBtn a {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .purpleBtn a {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn a {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn a {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .purpleBtn a {
    padding-bottom: 22px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .purpleBtn a {
    padding-bottom: 1.61054vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .purpleBtn a {
    padding-bottom: 1.61054vw;
  }
}

.pageTopBox {
  padding-bottom: 1.82292vw;
}

@media screen and (min-width: 1600px) {
  .pageTopBox {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox {
    padding-right: 12.53333vw;
    padding-bottom: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .pageTopBox {
    padding-right: 94px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    padding-right: 6.88141vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    padding-right: 6.88141vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .pageTopBox {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    padding-bottom: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    padding-bottom: 1.75695vw;
  }
}

.pageTopBox .pageTop {
  position: relative;
  margin: 0 0 0 auto;
  cursor: pointer;
  width: 2.8125vw;
  max-width: 54px;
  right: -1.35417vw;
}

@media screen and (min-width: 1600px) {
  .pageTopBox .pageTop {
    width: 54px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    width: 3.95315vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    width: 3.95315vw;
  }
}

@media screen and (min-width: 1600px) {
  .pageTopBox .pageTop {
    right: -26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    right: -1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    right: -1.90337vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  .pageTopBox .pageTop {
    right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  .pageTopBox .pageTop {
    right: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop {
    width: 6.93333vw;
    max-width: 52px;
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .pageTopBox .pageTop {
    width: 52px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    width: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    width: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .pageTopBox .pageTop {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    right: 0vw;
  }
}

/* 404
------------------------------------------------------------- */
#notFoundTop {
  background-color: #fafafa;
  padding-bottom: 7.8125vw;
}

@media screen and (min-width: 1600px) {
  #notFoundTop {
    padding-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop {
    padding-bottom: 16vw;
    background-color: #fcfcfc;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop {
    padding-bottom: 8.78477vw;
  }
}

#notFoundTop .notFoundCon {
  text-align: center;
  padding-top: 11.19792vw;
  padding-bottom: 3.38542vw;
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundCon {
    padding-top: 215px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon {
    padding-top: 15.73939vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon {
    padding-top: 15.73939vw;
  }
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundCon {
    padding-bottom: 65px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon {
    padding-bottom: 4.75842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon {
    padding-bottom: 4.75842vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundCon {
    padding-top: 11.33333vw;
    padding-bottom: 11.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundCon {
    padding-top: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon {
    padding-top: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon {
    padding-top: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundCon {
    padding-bottom: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon {
    padding-bottom: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon {
    padding-bottom: 6.22255vw;
  }
}

#notFoundTop .notFoundCon .notFoundTit {
  font-family: "Noto Serif JP", serif;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  line-height: 1.27;
  text-align: center;
  color: #6e147f;
  margin-bottom: 3.38542vw;
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundCon .notFoundTit {
    margin-bottom: 65px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon .notFoundTit {
    margin-bottom: 4.75842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon .notFoundTit {
    margin-bottom: 4.75842vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.67;
    letter-spacing: 1.35px;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .notFoundCon .notFoundTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundCon .notFoundTit {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon .notFoundTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon .notFoundTit {
    margin-bottom: 5.12445vw;
  }
}

#notFoundTop .notFoundCon .notFoundTxt {
  display: inline-block;
  font-family: "Noto Serif JP", serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2.5;
  color: #5a5a5a;
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 2.13333vw;
  }
}

#notFoundTop .notFoundCon .notFoundTxt a {
  color: inherit;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 2.4;
    letter-spacing: .75px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .notFoundCon .notFoundTxt {
    font-size: 2.66667vw;
  }
}

/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 2.08333vw;
  padding-bottom: 1.04167vw;
  clear: both;
}

@media screen and (min-width: 1600px) {
  .breadcrumb {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  .breadcrumb {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb {
    padding-top: 4vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 3.66032vw;
  }
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  color: #acabab;
  margin: 0 auto;
}

.breadcrumb ol::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  .breadcrumb ol {
    max-width: initial;
  }
}

.breadcrumb ol > li {
  display: inline;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #666;
}

@media screen and (min-width: 1600px) {
  .breadcrumb ol > li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb ol > li {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li a {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #666;
}

@media screen and (min-width: 1600px) {
  .breadcrumb ol > li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li:first-child a {
  text-decoration: none;
}

.breadcrumb ol > li + li::before {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  padding-right: 0.52083vw;
  padding-left: 0.41667vw;
  color: #444444;
  content: "＞";
}

@media screen and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-left: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
    font-size: 1.04167vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.73206vw;
  }
}

/* =====================================
ヘッダー
===================================== */
#header {
  position: relative;
  background-color: white;
  height: 5.20833vw;
  max-height: 100px;
}

@media screen and (min-width: 1600px) {
  #header {
    height: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    height: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header {
    height: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #header {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.9);
    transition: .5s;
    height: 19.33333vw;
    max-height: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header {
    height: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    height: 10.61493vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header {
    height: 10.61493vw;
  }
}

@media screen and (max-width: 767px) {
  #header.changeHeight {
    height: 13.33333vw;
    max-height: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header.changeHeight {
    height: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header.changeHeight {
    height: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header.changeHeight {
    height: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header.changeHeight .headerLogo {
    width: 27.33333vw;
    max-width: 205px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  #header.changeHeight .headerLogo {
    width: 205px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header.changeHeight .headerLogo {
    width: 15.00732vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header.changeHeight .headerLogo {
    width: 15.00732vw;
  }
}

#header .container {
  display: flex;
  align-items: center;
  height: 100%;
}

#header .headerLogo {
  width: 10.72917vw;
  max-width: 206px;
}

@media screen and (min-width: 1600px) {
  #header .headerLogo {
    width: 206px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerLogo {
    width: 15.08053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerLogo {
    width: 15.08053vw;
  }
}

@media screen and (max-width: 767px) {
  #header .headerLogo {
    transition: .5s;
    width: 35.33333vw;
    max-width: 265px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .headerLogo {
    width: 265px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerLogo {
    width: 19.39971vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerLogo {
    width: 19.39971vw;
  }
}

#header .navBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  padding-right: 5.33333vw;
  width: 12vw;
  max-width: 90px;
  height: 4.26667vw;
  max-height: 32px;
  cursor: pointer;
}

@media screen and (min-width: 1600px) {
  #header .navBtn {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #header .navBtn {
    width: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    width: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #header .navBtn {
    height: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    height: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    height: 2.34261vw;
  }
}

#header .navBtn span {
  display: inline-block;
  position: absolute;
  right: 5.33333vw;
  width: 6.66667vw;
  max-width: 50px;
  height: 1px;
  background-color: #4d4d4d;
  transition: transform .5s ease-in-out , opacity .5s .9s ease-in-out , background-color .5s .9s ease-in-out;
}

@media screen and (min-width: 1600px) {
  #header .navBtn span {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #header .navBtn span {
    width: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    width: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    width: 3.66032vw;
  }
}

#header .navBtn span:nth-of-type(1) {
  top: 3.73333vw;
}

@media screen and (min-width: 1600px) {
  #header .navBtn span:nth-of-type(1) {
    top: 28px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(1) {
    top: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(1) {
    top: 2.04978vw;
  }
}

#header .navBtn span:nth-of-type(2) {
  top: 49%;
}

#header .navBtn span:nth-of-type(3) {
  bottom: 3.73333vw;
}

@media screen and (min-width: 1600px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 28px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 2.04978vw;
  }
}

#header .navBtn.isOpen span {
  transition: transform .5s ease-in-out , opacity .5s ease-in-out;
}

#header .navBtn.isOpen span:nth-of-type(1), #header .navBtn.isOpen span:nth-of-type(3) {
  top: 50%;
  background-color: #fff;
}

#header .navBtn.isOpen span:nth-of-type(1) {
  transform: rotate(-135deg);
}

#header .navBtn.isOpen span:nth-of-type(2) {
  opacity: 0;
}

#header .navBtn.isOpen span:nth-of-type(3) {
  transform: rotate(135deg);
}

@media screen and (max-width: 767px) {
  #header .globalNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    visibility: hidden;
    -webkit-overflow-scrolling: touch;
    transition: visibility .7s .8s;
  }
  #header .globalNav.isShow {
    visibility: visible;
    transition: visibility .7s;
  }
  #header .globalNav.isShow::after {
    transition: transform .7s;
    transform: translateX(0);
  }
  #header .globalNav.isShow ul {
    transition: opacity .8s .8s, transform .7s .8s;
    opacity: 1;
    transform: translateY(0);
  }
  #header .globalNav::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(110, 20, 127, 0.9);
    z-index: -1;
    transform: translateX(100%);
    transition: transform .7s .9s;
  }
  #header .globalNav ul {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity .8s, transform .7s;
  }
}

#header .globalNav ul {
  display: flex;
  align-items: center;
}

#header .globalNav ul .nav {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.2;
  letter-spacing: 1.2px;
  color: #3b444b;
  margin-left: 3.90625vw;
}

@media screen and (min-width: 1600px) {
  #header .globalNav ul .nav {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul .nav {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul .nav {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNav ul .nav {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #header .globalNav ul .nav {
    margin-left: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul .nav {
    margin-left: 5.49048vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul .nav {
    margin-left: 5.49048vw;
  }
}

#header .globalNav ul .nav a {
  color: inherit;
}

@media screen and (max-width: 767px) {
  #header .globalNav ul {
    display: block;
    padding-left: 16.66667vw;
    padding-right: 5.33333vw;
    padding-top: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .globalNav ul {
    padding-left: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul {
    padding-left: 9.15081vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul {
    padding-left: 9.15081vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .globalNav ul {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .globalNav ul {
    padding-top: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul {
    padding-top: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul {
    padding-top: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #header .globalNav ul .nav {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    font-weight: bold;
    color: #fff;
    margin-left: 0vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .globalNav ul .nav {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul .nav {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul .nav {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .globalNav ul .nav {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .globalNav ul .nav {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul .nav {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul .nav {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .globalNav ul .nav {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .globalNav ul .nav {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .globalNav ul .nav {
    margin-bottom: 4.39239vw;
  }
}

/* =====================================================
フッター
===================================================== */
#footer .footerContents {
  background-color: #efe5f1;
  padding-top: 4.6875vw;
  padding-bottom: 7.29167vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerContents {
    padding-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-top: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-top: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerContents {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerContents {
    padding-top: 13.33333vw;
    padding-bottom: 14.66667vw;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerContents {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerContents {
    padding-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerContents {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerContents {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents {
    padding-left: 5.85652vw;
  }
}

#footer .footerContents .container {
  display: flex;
}

#footer .footerContents .container ul {
  margin-right: 5.72917vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerContents .container ul {
    margin-right: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents .container ul {
    margin-right: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents .container ul {
    margin-right: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerContents .container {
    display: block;
  }
  #footer .footerContents .container ul {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerContents .container ul {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents .container ul {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents .container ul {
    margin-right: 0vw;
  }
}

#footer .footerContents .nav {
  font-size: 2rem;
  font-size: 1.04167vw;
  line-height: 1.2;
  letter-spacing: 1.5px;
  color: #3b444b;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerContents .nav {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents .nav {
    font-size: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents .nav {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerContents .nav {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerContents .nav {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents .nav {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents .nav {
    margin-bottom: 2.19619vw;
  }
}

#footer .footerContents .nav:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #footer .footerContents .nav {
    letter-spacing: .75px;
    margin-bottom: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerContents .nav {
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents .nav {
    margin-bottom: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents .nav {
    margin-bottom: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerContents .nav:last-of-type {
    margin-bottom: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerContents .nav:last-of-type {
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerContents .nav:last-of-type {
    margin-bottom: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerContents .nav:last-of-type {
    margin-bottom: 3.07467vw;
  }
}

#footer .copyRight {
  display: block;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1;
  text-align: center;
  color: #3b444b;
  background-color: #f1f1f1;
  padding-top: 2.08333vw;
  padding-bottom: 2.08333vw;
}

@media screen and (min-width: 1600px) {
  #footer .copyRight {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRight {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRight {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyRight {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .copyRight {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRight {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRight {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .copyRight {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRight {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRight {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyRight {
    line-height: 1.2;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .copyRight {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRight {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRight {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .copyRight {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRight {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRight {
    padding-bottom: 2.92826vw;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: opacity 1s .2s, transform 1s .2s;
  backface-visibility: hidden;
}

.fadeIn img {
  backface-visibility: hidden;
}

.fadeIn.active {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn.active.fadeIn2 {
  transform: none;
}

.fadeIn.active .fadeIn_second {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn.active .fadeIn_third {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn.fadeIn2 {
  transform: translateY(30px);
}

.fadeIn .fadeIn_second {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity 1s .6s, transform 1s .6s;
}

.fadeIn .fadeIn_third {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity 1s 1s, transform 1s 1s;
}

/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

#loading {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: #ffffff;
  opacity: 1;
  z-index: 100000;
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop {
  padding-bottom: 13.02083vw;
}

@media screen and (min-width: 1600px) {
  #indexTop {
    padding-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop {
    padding-bottom: 18.30161vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop {
    padding-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop {
    padding-bottom: 33.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop {
    padding-bottom: 250px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop {
    padding-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop {
    padding-bottom: 18.30161vw;
  }
}

#indexTop .mainview {
  position: relative;
}

#indexTop .mainview img {
  max-width: 100%;
  width: 100%;
}

#indexTop .mainview .txtBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#indexTop .mainview .txt01 {
  max-width: 50.578%;
  width: 100%;
  position: absolute;
  top: 21.45833vw;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  #indexTop .mainview .txt01 {
    top: 412px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt01 {
    top: 30.16105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt01 {
    top: 30.16105vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt01 {
    top: 15.20833vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #indexTop .mainview .txt01 {
    top: 292px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt01 {
    top: 21.37628vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt01 {
    top: 21.37628vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt01 {
    top: 38.02083vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #indexTop .mainview .txt01 {
    top: 292px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt01 {
    top: 21.37628vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt01 {
    top: 21.37628vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .txt01 {
    max-width: 100%;
    top: 55.6vw;
    width: 83.06667vw;
    max-width: 623px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainview .txt01 {
    top: 417px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt01 {
    top: 30.52709vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt01 {
    top: 30.52709vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainview .txt01 {
    width: 623px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt01 {
    width: 45.60761vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt01 {
    width: 45.60761vw;
  }
}

#indexTop .mainview .txt02 {
  max-width: 29.832%;
  width: 100%;
  position: absolute;
  top: 30.57292vw;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  #indexTop .mainview .txt02 {
    top: 587px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt02 {
    top: 42.97218vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt02 {
    top: 42.97218vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt02 {
    top: 21.71875vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #indexTop .mainview .txt02 {
    top: 417px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt02 {
    top: 30.52709vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt02 {
    top: 30.52709vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt02 {
    top: 54.29688vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #indexTop .mainview .txt02 {
    top: 417px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt02 {
    top: 30.52709vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt02 {
    top: 30.52709vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .txt02 {
    max-width: 100%;
    top: 69.33333vw;
    width: 65.06667vw;
    max-width: 488px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainview .txt02 {
    top: 520px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt02 {
    top: 38.06735vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt02 {
    top: 38.06735vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainview .txt02 {
    width: 488px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt02 {
    width: 35.72474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt02 {
    width: 35.72474vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainview .txt03 {
    max-width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 86vw;
    width: 75.73333vw;
    max-width: 568px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainview .txt03 {
    top: 645px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt03 {
    top: 47.21816vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt03 {
    top: 47.21816vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainview .txt03 {
    width: 568px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainview .txt03 {
    width: 41.58126vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainview .txt03 {
    width: 41.58126vw;
  }
}

#indexTop .aboutBathPowder {
  padding-top: 4.42708vw;
  padding-bottom: 6.09375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder {
    padding-top: 85px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder {
    padding-top: 6.22255vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder {
    padding-top: 6.22255vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder {
    padding-bottom: 117px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder {
    padding-bottom: 8.56515vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder {
    padding-bottom: 8.56515vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder {
    padding-top: 14.66667vw;
    padding-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder {
    padding-top: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder {
    padding-top: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder {
    padding-top: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder {
    padding-bottom: 6.58858vw;
  }
}

#indexTop .aboutBathPowder .aboutBathPowderTit {
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1.4px;
  text-align: center;
  color: #6e147f;
  margin-bottom: 2.1875vw;
}

#indexTop .aboutBathPowder .aboutBathPowderTit span {
  display: block;
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: normal;
  line-height: 1.31;
  text-align: center;
  color: #3b444b;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    font-size: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    margin-bottom: 42px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    margin-bottom: 3.07467vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    margin-bottom: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    letter-spacing: .7px;
    margin-bottom: 7.2vw;
  }
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.17;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit span {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    margin-bottom: 54px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    margin-bottom: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTit {
    margin-bottom: 3.95315vw;
  }
}

#indexTop .aboutBathPowder .aboutBathPowderTxt {
  font-family: "Noto Serif JP", serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  letter-spacing: 1.2px;
  text-align: center;
  color: #3b444b;
  margin-bottom: 3.64583vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 2.4;
    letter-spacing: .75px;
    margin-bottom: 12.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    margin-bottom: 94px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    margin-bottom: 6.88141vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .aboutBathPowderTxt {
    margin-bottom: 6.88141vw;
  }
}

#indexTop .aboutBathPowder .img {
  max-width: 47.947%;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4.89583vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .img {
    margin-bottom: 94px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .img {
    margin-bottom: 6.88141vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .img {
    margin-bottom: 6.88141vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .img {
    max-width: 100%;
    width: 78.66667vw;
    max-width: 590px;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .img {
    width: 590px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .img {
    width: 43.1918vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .img {
    width: 43.1918vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .img {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .img {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .img {
    margin-bottom: 6.58858vw;
  }
}

#indexTop .aboutBathPowder .youtubeBox {
  max-width: 952px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .youtubeBox {
    padding: 0;
  }
}

#indexTop .aboutBathPowder .youtube {
  position: relative;
  max-width: 100%;
  padding-bottom: 56.25%;
  background-color: #f1f1f1;
  margin-top: 15.10417vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutBathPowder .youtube {
    margin-top: 290px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .youtube {
    margin-top: 21.22987vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .youtube {
    margin-top: 21.22987vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutBathPowder .youtube {
    margin-top: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutBathPowder .youtube {
    margin-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutBathPowder .youtube {
    margin-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutBathPowder .youtube {
    margin-top: 10.98097vw;
  }
}

#indexTop .aboutBathPowder .youtube iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#indexTop .bathPowderDesc {
  padding-top: 6.09375vw;
  padding-bottom: 8.95833vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc {
    padding-top: 117px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc {
    padding-top: 8.56515vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc {
    padding-top: 8.56515vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc {
    padding-bottom: 172px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc {
    padding-bottom: 12.59151vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc {
    padding-bottom: 12.59151vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc {
    padding-top: 12vw;
    padding-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc {
    padding-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc {
    padding-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc {
    padding-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc {
    padding-bottom: 7.32064vw;
  }
}

#indexTop .bathPowderDesc .bathPowderDescTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.6rem;
  font-size: 1.875vw;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #3b444b;
  margin-bottom: 3.64583vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    font-size: 3.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    font-size: 2.63543vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescTit {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .bathPowderDesc .bathPowderDescTxt {
  max-width: 76.304%;
  width: 100%;
  font-family: "Noto Serif JP", serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  letter-spacing: 1.2px;
  text-align: center;
  color: #3b444b;
  margin: 0 auto;
  margin-bottom: 4.6875vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    max-width: 100%;
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 2.4;
    letter-spacing: .75px;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescTxt {
    margin-bottom: 6.58858vw;
  }
}

#indexTop .bathPowderDesc .bathPowderDescPoint {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint {
    margin-bottom: 37.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint {
    margin-bottom: 280px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint {
    margin-bottom: 20.4978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint {
    margin-bottom: 20.4978vw;
  }
}

#indexTop .bathPowderDesc .bathPowderDescPoint .point {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: 2.4px;
  text-align: center;
  color: #6e147f;
  border: solid 2px #6e147f;
  border-radius: 100%;
  width: 12.5vw;
  max-width: 240px;
  height: 12.5vw;
  max-height: 240px;
  margin-right: 2.08333vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    width: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    width: 17.56955vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    width: 17.56955vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    height: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    height: 17.56955vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    height: 17.56955vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-right: 2.92826vw;
  }
}

#indexTop .bathPowderDesc .bathPowderDescPoint .point:nth-of-type(4n) {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    letter-spacing: 1.2px;
    border: solid 1px #6e147f;
    width: 26.13333vw;
    max-width: 196px;
    height: 26.13333vw;
    max-height: 196px;
    margin-right: 4vw;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    width: 196px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    width: 14.34846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    width: 14.34846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    height: 196px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    height: 14.34846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    height: 14.34846vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point:nth-of-type(4n) {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point:nth-of-type(4n) {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point:nth-of-type(4n) {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point:nth-of-type(4n) {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .bathPowderDescPoint .point:nth-of-type(5n) {
    margin-right: 0;
  }
}

#indexTop .bathPowderDesc .mainEfficacy {
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy {
    margin-bottom: 84px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy {
    margin-bottom: 6.14934vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy {
    margin-bottom: 6.14934vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy {
    margin-bottom: 17.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy {
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy {
    margin-bottom: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy {
    margin-bottom: 9.51684vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.6rem;
  font-size: 1.875vw;
  line-height: 1.78;
  text-align: center;
  color: #3b444b;
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    font-size: 3.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    font-size: 2.63543vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyTit {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .flex {
    display: block;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
  max-width: 50%;
  width: 100%;
  padding-right: 2.60417vw;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    margin-bottom: 1.83016vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
  padding-right: 0vw;
  padding-left: 2.60417vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    max-width: 100%;
    padding-right: 0vw;
    margin-bottom: 14vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    margin-bottom: 105px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    margin-bottom: 7.68668vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox {
    margin-bottom: 7.68668vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox:nth-of-type(2n) {
    padding-left: 0vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
  max-width: 46.656%;
  width: 100%;
  font-family: "Noto Serif JP", serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  line-height: 1;
  letter-spacing: 0.6px;
  text-align: center;
  color: #fff;
  background-color: #6e147f;
  border-radius: 10px;
  padding-top: 0.72917vw;
  padding-bottom: 0.72917vw;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-top: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-top: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-top: 1.02489vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-bottom: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    max-width: none;
    min-width: 40.596%;
    width: auto;
    display: inline-block;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.35px;
    border-radius: 5px;
    padding-top: 1.86667vw;
    padding-bottom: 1.86667vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-top: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTit {
    margin-bottom: 2.19619vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  margin-bottom: 2.34375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    margin-bottom: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 2rem;
    font-size: 1.04167vw;
    margin-bottom: 8.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    margin-bottom: 65px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    margin-bottom: 4.75842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .mainEfficacyBoxTxt {
    margin-bottom: 4.75842vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
  display: flex;
  align-items: center;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    font-size: 2rem;
    font-size: 1.04167vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement {
    margin-bottom: 0vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: #5a5a5a;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 2.4rem;
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt {
    font-size: 3.2vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
  display: inline-block;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: #6e147f;
  border-radius: 5px;
  border: solid 1px #6e147f;
  padding-top: 0.41667vw;
  padding-bottom: 0.41667vw;
  padding-right: 1.04167vw;
  padding-left: 1.04167vw;
  margin-bottom: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-top: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-top: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    margin-bottom: 0.43924vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    margin-bottom: 0.43924vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 2.3rem;
    font-size: 1.19792vw;
    border-radius: 3px;
    padding-top: 1.86667vw;
    padding-bottom: 1.86667vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
    margin-bottom: 1.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 1.68375vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 1.68375vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    font-size: 3.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-top: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    margin-bottom: 9px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    margin-bottom: 0.65886vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt .purple {
    margin-bottom: 0.65886vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
  content: "＋";
  display: inline-block;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: #6e147f;
  vertical-align: top;
  margin-top: 0.41667vw;
  margin-right: 0.26042vw;
  margin-left: 0.41667vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-top: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-top: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-top: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-left: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-left: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-left: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 2.13333vw;
    margin-right: 0.66667vw;
    margin-left: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-left: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-left: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02::before {
    margin-left: 0.58565vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
  padding-right: 0.52083vw;
  padding-left: 0.52083vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-left: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .purple {
    padding-left: 1.0981vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
  display: block;
  text-align: right;
  padding-right: 0.52083vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
    padding-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt02 .gray {
    padding-right: 1.46413vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
  content: "＝";
  display: inline-block;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: #6e147f;
  vertical-align: top;
  margin-top: 0.52083vw;
  margin-right: 1.30208vw;
  margin-left: 0.41667vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-left: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-left: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-left: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 1.86667vw;
    margin-right: 2.66667vw;
    margin-left: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-top: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-left: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03::before {
    margin-left: 0.87848vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
  font-size: 3.5rem;
  font-size: 1.82292vw;
  border: none;
  padding-top: 0vw;
  padding-bottom: 0.10417vw;
  padding-right: 0vw;
  padding-left: 0vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 3.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 2.56223vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 4.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-top: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-top: 0vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 5.2rem;
    font-size: 2.70833vw;
    padding-bottom: 0.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 5.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    font-size: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-bottom: 0.29283vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .purple {
    padding-bottom: 0.29283vw;
  }
}

#indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
  display: block;
  text-align: right;
  padding-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
    padding-right: 24px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
    padding-right: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
    padding-right: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
    padding-right: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
    padding-right: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
    padding-right: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .bathPowderDesc .mainEfficacy .mainEfficacyBox .supplement .txt.txt03 .gray {
    padding-right: 2.34261vw;
  }
}

#indexTop .howToUse {
  padding-top: 8.95833vw;
  padding-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse {
    padding-top: 172px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse {
    padding-top: 12.59151vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse {
    padding-top: 12.59151vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse {
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse {
    padding-bottom: 7.32064vw;
  }
}

#indexTop .howToUse .howToUseTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  line-height: 1.31;
  text-align: center;
  color: #3b444b;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .howToUseTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .howToUseTit {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .howToUseTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .howToUseTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .howToUseTit {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .howToUseTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .howToUseTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .howToUseTit {
    line-height: 1.78;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .howToUseTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .howToUseTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .howToUseTit {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .howToUse .howToUseTxt {
  font-family: "Noto Serif JP", serif;
  font-size: 2.1rem;
  font-size: 1.09375vw;
  line-height: 2;
  letter-spacing: 1.58px;
  text-align: center;
  color: #3b444b;
  margin-bottom: 2.34375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 2.1rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 1.53734vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 1.53734vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 2.8vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .howToUseTxt {
    margin-bottom: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .howToUseTxt {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .howToUseTxt {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 2.4rem;
    font-size: 1.25vw;
    letter-spacing: .9px;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .howToUse .howToUseTxt {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .howToUseTxt {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .howToUseTxt {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .howToUseTxt {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .img {
    padding: 0;
  }
}

#indexTop .howToUse .recommended {
  padding-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .recommended {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .recommended {
    padding-top: 12.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .recommended {
    padding-top: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended {
    padding-top: 6.95461vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended {
    padding-top: 6.95461vw;
  }
}

#indexTop .howToUse .recommended .recommendedTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  line-height: 1.31;
  text-align: center;
  color: #3b444b;
  margin-bottom: 4.6875vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedTit {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.78;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedTit {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedTit {
    margin-bottom: 6.58858vw;
  }
}

#indexTop .howToUse .recommended .recommendedList .recommendedListTit {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListTit {
    font-size: 2.66667vw;
  }
}

#indexTop .howToUse .recommended .recommendedList .recommendedListCon {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    margin-bottom: 5.85652vw;
  }
}

#indexTop .howToUse .recommended .recommendedList .recommendedListCon:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 2rem;
    font-size: 1.04167vw;
    margin-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .howToUse .recommended .recommendedList .recommendedListCon {
    margin-bottom: 8.05271vw;
  }
}

#indexTop .faq {
  padding-top: 3.125vw;
  padding-bottom: 4.01042vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .faq {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .faq {
    padding-bottom: 77px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq {
    padding-bottom: 5.6369vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq {
    padding-bottom: 5.6369vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq {
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq {
    padding-bottom: 7.32064vw;
  }
}

#indexTop .faq .faqTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  line-height: 1.31;
  text-align: center;
  color: #3b444b;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqTit {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq .faqTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqTit {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqTit {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqTit {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq .faqTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.78;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .faq .faqTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqTit {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqTit {
    margin-bottom: 6.58858vw;
  }
}

#indexTop .faq .faqList {
  margin-bottom: 5.20833vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqList {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqList {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqList {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq .faqList {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqList {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqList {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqList {
    margin-bottom: 7.32064vw;
  }
}

#indexTop .faq .faqListTit {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  border-bottom: solid 1px #707070;
  padding-bottom: 0.20833vw;
  margin-bottom: 1.30208vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqListTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListTit {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq .faqListTit {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqListTit {
    padding-bottom: 4px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListTit {
    padding-bottom: 0.29283vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListTit {
    padding-bottom: 0.29283vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqListTit {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListTit {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListTit {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq .faqListTit {
    font-size: 2rem;
    font-size: 1.04167vw;
    padding-bottom: 0.53333vw;
    margin-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqListTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .faq .faqListTit {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqListTit {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListTit {
    padding-bottom: 0.29283vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListTit {
    padding-bottom: 0.29283vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqListTit {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListTit {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListTit {
    margin-bottom: 1.83016vw;
  }
}

#indexTop .faq .faqListCon {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqListCon {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListCon {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListCon {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq .faqListCon {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .faq .faqListCon {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListCon {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListCon {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .faq .faqListCon:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .faq .faqListCon {
    font-size: 2rem;
    font-size: 1.04167vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqListCon {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListCon {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListCon {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .faq .faqListCon {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .faq .faqListCon {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .faq .faqListCon {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .faq .faqListCon {
    margin-bottom: 7.32064vw;
  }
}

#indexTop .attentionOnUsing {
  padding-top: 4.01042vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .attentionOnUsing {
    padding-top: 77px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing {
    padding-top: 5.6369vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing {
    padding-top: 5.6369vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .attentionOnUsing {
    padding-top: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .attentionOnUsing {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing {
    padding-top: 7.32064vw;
  }
}

#indexTop .attentionOnUsing .attentionOnUsingTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  line-height: 1.31;
  text-align: center;
  color: #3b444b;
  margin-bottom: 3.64583vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.78;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing .attentionOnUsingTit {
    margin-bottom: 5.12445vw;
  }
}

#indexTop .attentionOnUsing .attentionList {
  max-width: 82.781%;
  margin: 0 auto;
}

#indexTop .attentionOnUsing .attentionList li {
  display: flex;
  align-items: baseline;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
}

@media screen and (min-width: 1600px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 2.13333vw;
  }
}

#indexTop .attentionOnUsing .attentionList li::before {
  content: "●";
}

@media screen and (max-width: 767px) {
  #indexTop .attentionOnUsing .attentionList {
    max-width: 100%;
  }
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .attentionOnUsing .attentionList li {
    font-size: 2.66667vw;
  }
}

/* ==============================================
トップページ
============================================== */
#zapparelTop {
  padding-bottom: 7.55208vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop {
    padding-bottom: 145px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop {
    padding-bottom: 10.61493vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop {
    padding-bottom: 10.61493vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop {
    padding-bottom: 19.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop {
    padding-bottom: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop {
    padding-bottom: 10.61493vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop {
    padding-bottom: 10.61493vw;
  }
}

#zapparelTop .mainview img {
  max-width: 100%;
  width: 100%;
}

#zapparelTop .mainview .txtBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#zapparelTop .mainview .txt01 {
  max-width: 44.095%;
  width: 100%;
  position: absolute;
  top: 17.1875vw;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .mainview .txt01 {
    top: 330px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt01 {
    top: 24.15813vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt01 {
    top: 24.15813vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt01 {
    top: 12.5vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt01 {
    top: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt01 {
    top: 17.56955vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt01 {
    top: 17.56955vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt01 {
    top: 31.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt01 {
    top: 240px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt01 {
    top: 17.56955vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt01 {
    top: 17.56955vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .mainview .txt01 {
    max-width: 100%;
    top: 47.46667vw;
    width: 78.66667vw;
    max-width: 590px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt01 {
    top: 356px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt01 {
    top: 26.06149vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt01 {
    top: 26.06149vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt01 {
    width: 590px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt01 {
    width: 43.1918vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt01 {
    width: 43.1918vw;
  }
}

#zapparelTop .mainview .txt02 {
  max-width: 35.2234%;
  width: 100%;
  position: absolute;
  top: 27.60417vw;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .mainview .txt02 {
    top: 530px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt02 {
    top: 38.79941vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt02 {
    top: 38.79941vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt02 {
    top: 19.79167vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt02 {
    top: 380px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt02 {
    top: 27.81845vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt02 {
    top: 27.81845vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt02 {
    top: 49.47917vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt02 {
    top: 380px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt02 {
    top: 27.81845vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt02 {
    top: 27.81845vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .mainview .txt02 {
    max-width: 100%;
    top: 66.4vw;
    width: 58.93333vw;
    max-width: 442px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt02 {
    top: 498px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt02 {
    top: 36.45681vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt02 {
    top: 36.45681vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .mainview .txt02 {
    width: 442px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .mainview .txt02 {
    width: 32.35725vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .mainview .txt02 {
    width: 32.35725vw;
  }
}

#zapparelTop .zapparelAbout {
  padding-top: 8.85417vw;
  padding-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelAbout {
    padding-top: 170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout {
    padding-top: 12.4451vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout {
    padding-top: 12.4451vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelAbout {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelAbout {
    padding-top: 9.86667vw;
    padding-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelAbout {
    padding-top: 74px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout {
    padding-top: 5.41728vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout {
    padding-top: 5.41728vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelAbout {
    padding-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout {
    padding-bottom: 8.05271vw;
  }
}

#zapparelTop .zapparelAbout .zapparelAboutTit {
  font-size: 7rem;
  font-size: 3.64583vw;
  line-height: 1;
  letter-spacing: 5.25px;
  text-align: center;
  color: #6e147f;
  margin-bottom: 4.6875vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    font-size: 5.12445vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    font-weight: bold;
    letter-spacing: .7px;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout .zapparelAboutTit {
    margin-bottom: 5.12445vw;
  }
}

#zapparelTop .zapparelAbout .zapparelAboutTxt {
  font-family: "Noto Serif JP", serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2.45;
  letter-spacing: 1.2px;
  text-align: center;
  color: #3b444b;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 2.4;
    letter-spacing: .75px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #zapparelTop .zapparelAbout .zapparelAboutTxt {
    font-size: 2.66667vw;
  }
}

#zapparelTop .zapparelFirstProduction {
  padding-bottom: 4.6875vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelFirstProduction {
    padding-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction {
    padding-bottom: 6.58858vw;
  }
}

#zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: 1.6px;
  text-align: center;
  color: #3b444b;
  margin-bottom: 4.6875vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 1.4;
    letter-spacing: .5px;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .zapparelFirstProductionTit {
    margin-bottom: 6.58858vw;
  }
}

#zapparelTop .zapparelFirstProduction .flex {
  max-width: 62.402%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 4.6875vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .flex {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .flex {
    margin-top: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .flex {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelFirstProduction .flex {
    max-width: 100%;
    margin-top: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .flex {
    margin-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .flex {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .flex {
    margin-top: 6.58858vw;
  }
}

#zapparelTop .zapparelFirstProduction .flex .img {
  max-width: 47.85%;
  width: 100%;
  margin-top: 2.8125vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .flex .img {
    margin-top: 54px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .flex .img {
    margin-top: 3.95315vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .flex .img {
    margin-top: 3.95315vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelFirstProduction .flex .img {
    margin-top: 6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .flex .img {
    margin-top: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .flex .img {
    margin-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .flex .img {
    margin-top: 3.29429vw;
  }
}

#zapparelTop .zapparelFirstProduction .flex .img.first {
  max-width: 100%;
  margin-top: 0vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelFirstProduction .flex .img.first {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelFirstProduction .flex .img.first {
    margin-top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelFirstProduction .flex .img.first {
    margin-top: 0vw;
  }
}

#zapparelTop .zapparelSecondProduction {
  padding-top: 4.6875vw;
  padding-bottom: 5.72917vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction {
    padding-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction {
    padding-top: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction {
    padding-top: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction {
    padding-top: 12vw;
    padding-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction {
    padding-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction {
    padding-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction {
    padding-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction {
    padding-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction {
    padding-bottom: 8.05271vw;
  }
}

#zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: 1.6px;
  text-align: center;
  color: #3b444b;
  margin-bottom: 2.60417vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 2.34261vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 1.4;
    letter-spacing: .5px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionTit {
    margin-bottom: 0.73206vw;
  }
}

#zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
  font-family: "Noto Serif JP", serif;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
  line-height: 1.27;
  letter-spacing: 1.1px;
  text-align: center;
  color: #3b444b;
  margin-bottom: 4.16667vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 1.4;
    letter-spacing: .5px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .zapparelSecondProductionSubTit {
    margin-bottom: 5.85652vw;
  }
}

#zapparelTop .zapparelSecondProduction .mainImg {
  max-width: 62.402%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .mainImg {
    max-width: 100%;
  }
}

#zapparelTop .zapparelSecondProduction .flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

#zapparelTop .zapparelSecondProduction .flex .img {
  max-width: 23.697%;
  width: 100%;
  margin-right: 1.04167vw;
  margin-top: 2.8125vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-right: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-top: 54px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-top: 3.95315vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-top: 3.95315vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-right: 1.33333vw;
    margin-top: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-top: 54px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-top: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelSecondProduction .flex .img {
    margin-top: 3.95315vw;
  }
}

#zapparelTop .zapparelSecondProduction .flex .img:nth-of-type(4n) {
  margin-right: 0;
}

#zapparelTop .zapparelDesc {
  padding-top: 5.72917vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelDesc {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc {
    padding-top: 8.05271vw;
  }
}

#zapparelTop .zapparelDesc .zapparelDescTit {
  font-family: "Noto Serif JP", serif;
  font-size: 3.9rem;
  font-size: 2.03125vw;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1.95px;
  text-align: center;
  color: #3b444b;
  margin-bottom: 2.08333vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 3.9rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 2.85505vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 2.85505vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 5.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    line-height: 1.4;
    letter-spacing: .5px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescTit {
    margin-bottom: 0.73206vw;
  }
}

#zapparelTop .zapparelDesc .zapparelDescSubTit {
  font-family: "Noto Serif JP", serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 1.2px;
  text-align: center;
  color: #3b444b;
  margin-bottom: 5.9375vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 1.75695vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    margin-bottom: 114px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    margin-bottom: 8.34553vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    margin-bottom: 8.34553vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 2.2rem;
    font-size: 1.14583vw;
    line-height: 1.4;
    letter-spacing: .5px;
    margin-bottom: 15.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    margin-bottom: 114px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    margin-bottom: 8.34553vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .zapparelDescSubTit {
    margin-bottom: 8.34553vw;
  }
}

#zapparelTop .zapparelDesc .img {
  margin-bottom: 15.10417vw;
}

@media screen and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .img {
    margin-bottom: 290px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .img {
    margin-bottom: 21.22987vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .img {
    margin-bottom: 21.22987vw;
  }
}

@media screen and (max-width: 767px) {
  #zapparelTop .zapparelDesc .img {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #zapparelTop .zapparelDesc .img {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #zapparelTop .zapparelDesc .img {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #zapparelTop .zapparelDesc .img {
    margin-bottom: 10.98097vw;
  }
}

/* ==============================================
トップページ
============================================== */
#privacyTop {
  background-color: #fafafa;
  padding-bottom: 16.40625vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop {
    padding-bottom: 315px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop {
    padding-bottom: 23.06003vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop {
    padding-bottom: 23.06003vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop {
    padding-bottom: 57.33333vw;
    background-color: #fcfcfc;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop {
    padding-bottom: 430px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop {
    padding-bottom: 31.47877vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop {
    padding-bottom: 31.47877vw;
  }
}

#privacyTop .privacyPolicy {
  padding-top: 11.19792vw;
  padding-bottom: 3.38542vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy {
    padding-top: 215px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy {
    padding-top: 15.73939vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy {
    padding-top: 15.73939vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy {
    padding-bottom: 65px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy {
    padding-bottom: 4.75842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy {
    padding-bottom: 4.75842vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy {
    padding-top: 11.33333vw;
    padding-bottom: 11.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy {
    padding-top: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy {
    padding-top: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy {
    padding-top: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy {
    padding-bottom: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy {
    padding-bottom: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy {
    padding-bottom: 6.22255vw;
  }
}

#privacyTop .privacyPolicy .privacyPolicyTit {
  font-family: "Noto Serif JP", serif;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  line-height: 1.27;
  text-align: center;
  color: #6e147f;
  margin-bottom: 3.38542vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    margin-bottom: 65px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    margin-bottom: 4.75842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    margin-bottom: 4.75842vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.67;
    letter-spacing: 1.35px;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTit {
    margin-bottom: 5.12445vw;
  }
}

#privacyTop .privacyPolicy .privacyPolicyTxt {
  font-family: "Noto Serif JP", serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 2.4;
    letter-spacing: .75px;
    text-align: center;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyPolicyTxt {
    margin-bottom: 3.66032vw;
  }
}

#privacyTop .privacyPolicy .privacyListTit {
  font-family: "Noto Serif JP", serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  margin-top: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListTit {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListTit {
    margin-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListTit {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 2.4;
    letter-spacing: .75px;
    text-align: center;
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyListTit {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListTit {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListTit {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListTit {
    margin-top: 3.66032vw;
  }
}

#privacyTop .privacyPolicy .privacyListCon {
  font-family: "Noto Serif JP", serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListCon {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListCon {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListCon {
    margin-bottom: 2.19619vw;
  }
}

#privacyTop .privacyPolicy .privacyListCon:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 2.4;
    letter-spacing: .75px;
    text-align: center;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .privacyPolicy .privacyListCon {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .privacyPolicy .privacyListCon {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .privacyPolicy .privacyListCon {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .privacyPolicy .privacyListCon {
    margin-bottom: 3.66032vw;
  }
}

#privacyTop .actOnSCT {
  padding-top: 3.38542vw;
  padding-bottom: 5.83333vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .actOnSCT {
    padding-top: 65px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT {
    padding-top: 4.75842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT {
    padding-top: 4.75842vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .actOnSCT {
    padding-bottom: 112px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT {
    padding-bottom: 8.19912vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT {
    padding-bottom: 8.19912vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .actOnSCT {
    padding-top: 11.33333vw;
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .actOnSCT {
    padding-top: 85px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT {
    padding-top: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT {
    padding-top: 6.22255vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .actOnSCT {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT {
    padding-bottom: 4.39239vw;
  }
}

#privacyTop .actOnSCT .actOnSCTTit {
  font-family: "Noto Serif JP", serif;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  line-height: 1.27;
  color: #6e147f;
  text-align: center;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.67;
    letter-spacing: 1.35px;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTTit {
    margin-bottom: 3.66032vw;
  }
}

#privacyTop .actOnSCT .actOnSCTList {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTList {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTList {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTList {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-family: "Noto Serif JP", serif;
    font-size: 2rem;
    font-size: 1.04167vw;
    font-weight: normal;
    line-height: 2.4;
    letter-spacing: .75px;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .actOnSCT .actOnSCTList {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .actOnSCT .actOnSCTList {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT .actOnSCTList {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT .actOnSCTList {
    margin-bottom: 3.66032vw;
  }
}

#privacyTop .actOnSCT p {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  color: #5a5a5a;
}

@media screen and (min-width: 1600px) {
  #privacyTop .actOnSCT p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT p {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .actOnSCT p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .actOnSCT p {
    font-family: "Noto Serif JP", serif;
    font-size: 2rem;
    font-size: 1.04167vw;
    font-weight: normal;
    line-height: 2.4;
    letter-spacing: .75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .actOnSCT p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .actOnSCT p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .actOnSCT p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .actOnSCT p {
    font-size: 2.66667vw;
  }
}

#privacyTop .contact {
  padding-top: 5.83333vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .contact {
    padding-top: 112px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact {
    padding-top: 8.19912vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact {
    padding-top: 8.19912vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .contact {
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .contact {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact {
    padding-top: 4.39239vw;
  }
}

#privacyTop .contact .contactTit {
  font-family: "Noto Serif JP", serif;
  font-size: 2.2rem;
  font-size: 1.14583vw;
  line-height: 1.27;
  color: #6e147f;
  text-align: center;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #privacyTop .contact .contactTit {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact .contactTit {
    font-size: 1.61054vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact .contactTit {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .contact .contactTit {
    font-size: 2.93333vw;
  }
}

@media screen and (min-width: 1600px) {
  #privacyTop .contact .contactTit {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact .contactTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact .contactTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .contact .contactTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.67;
    letter-spacing: 1.35px;
    margin-bottom: 6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .contact .contactTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact .contactTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact .contactTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .contact .contactTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .contact .contactTit {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact .contactTit {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact .contactTit {
    margin-bottom: 3.29429vw;
  }
}

#privacyTop .contact .contactTxt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  line-height: 2;
  text-align: center;
  color: #5a5a5a;
}

@media screen and (min-width: 1600px) {
  #privacyTop .contact .contactTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact .contactTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact .contactTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #privacyTop .contact .contactTxt {
    font-size: 2.13333vw;
  }
}

#privacyTop .contact .contactTxt a {
  color: inherit;
}

@media screen and (max-width: 767px) {
  #privacyTop .contact .contactTxt {
    font-family: "Noto Serif JP", serif;
    font-size: 2rem;
    font-size: 1.04167vw;
    font-weight: normal;
    line-height: 2.4;
    letter-spacing: .75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #privacyTop .contact .contactTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #privacyTop .contact .contactTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #privacyTop .contact .contactTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #privacyTop .contact .contactTxt {
    font-size: 2.66667vw;
  }
}
